@use '../../variables' as *;

.tk-step-wizard {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        gap: 2rem;
        margin-bottom: 2rem;

        & .title {
            >h3 {
                margin-top: .4rem;
            }
        }
    }

    &__content {
        flex: 1;
        overflow: hidden auto;
    }

    &__footer {
        position: relative;
        width: 100%;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        min-height: 40px;
        padding-top: .5rem;

        .controls {
            display: flex;
            gap: 1rem;
        }
    }
}

.steps {
    display: flex;
    flex: 1;
    max-width: 80%;

    &__item {
        display: flex;
        position: relative;
        height: 100%;

        svg,
        .label,
        .step-num {
            color: $fade-gray2;
        }

        &.checked,
        &.warning {

            svg,
            .label,
            .step-num {
                color: $primary-color;
                font-weight: 600;
            }

            .steps__item__line {
                .loading {
                    border-top: 6px solid $primary-color;
                }
            }
        }

        &.warning {

            svg,
            .label,
            .step-num {
                color: $warning-color;
            }
        }

        &.error {

            svg,
            .label,
            .step-num {
                color: $danger2-color;
            }
        }

        &.completed {

            svg,
            .label,
            .step-num {
                color: $light-green-color;
            }
        }

        &__type {
            position: relative;
            display: flex;

            .step-num {
                position: absolute;
                font-weight: 600;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &>.label {
                position: absolute;
                top: 2.5rem;
                width: max-content;
                left: 50%;
                transform: translateX(-50%);
                font-weight: 500;
            }

            svg {
                width: 2.2rem;
                height: auto;
            }
        }

        &__line {
            position: relative;
            transform: scaleX(1.02);
            width: 99%;
            margin: auto;

            .loading {
                border-top: 6px solid $fade-gray2;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                z-index: 10;
                transition: .4s width ease-out;
                border-radius: 10rem;
            }

            .placeholder {
                border-top: 6px solid $fade-gray2;
                position: absolute;
                width: 100%;
                top: 50%;
                transform: translateY(-50%);
                border-radius: 10rem;
            }
        }
    }

}