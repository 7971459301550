// create all the global css here...
@use 'variables' as *;
@use '../stylesheets/pages/' as *;
@use '../stylesheets/components/' as *;


* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    font-family: $default-font;
    font-size: $base-font-size;
    color: $black;
    background-color: $background-color;
    overflow: hidden;
}

svg {
    height: auto;
}

ul {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
    }
}

a {
    text-decoration: none;
}

.arabic-text {
    font-family: $arabic-font !important;
    font-optical-sizing: auto;
}

/* ===== Scrollbar CSS ===== */
@supports selector(::-webkit-scrollbar) {

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        height: 14px;
        width: 14px;
        transition: 0.8s;
    }

    *::-webkit-scrollbar-track {
        background: $scroll-body-color;
        border-radius: 60rem;
    }

    *::-webkit-scrollbar-thumb {
        background-color: $white;
        border-radius: 10px;
        border: 3px solid $scroll-body-color;
        margin-right: 1rem;

    }

    *::-webkit-scrollbar-corner {
        background: $scroll-body-color;
        border: 1px solid $scroll-body-color;
    }

}

@supports not selector(::-webkit-scrollbar) {

    // fallback
    * {
        scrollbar-width: thin;
        scrollbar-color: $white $scroll-body-color;
    }
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    padding: 0;
}

h1 {
    font-size: 30px;
}

h2 {
    font-size: 25px;
}


.flex {
    display: flex;
    flex-direction: row;

    &.align-center {
        align-items: center;
    }

    &.column {
        flex-direction: column;

        &.align-center {
            justify-content: center;
        }
    }

    &.gap-2 {
        gap: 2rem;
    }

    &.gap-1 {
        gap: 1rem;
    }

    &.gap-05 {
        gap: .5rem;
    }

    &.gap-03 {
        gap: .3rem;
    }

    &.wrap {
        flex-wrap: wrap;
    }

    &.center {
        align-items: center;
        justify-content: center;
    }
}

.overflow-hidden {
    overflow: hidden;
}

.text-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.capitalize {
    text-transform: capitalize;
}

.italic {
    font-style: italic;
}

.disabled {
    pointer-events: none;
    opacity: .3 !important;
}

strong {
    color: $primary-color;
}

.w100 {
    width: 100%;
}

.h100 {
    height: 100%;
}

.responsive-img {
    width: 100%;
    height: auto;
}

p {
    font-size: 12px;
}

.empty {
    display: flex;
    align-items: center;
    justify-content: center;
}

h3 {
    font-weight: 600;
}

textarea {
    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
}

.bold {
    font-weight: 600;
}

.semi-bold {
    font-weight: 500;
}

.fade {
    // color: $fade-gray2;
    color: #717171;
}

ul {
    &.indent-1 {
        padding-left: 1rem;
    }
}

li {
    &.has-list-style {
        display: list-item;
        list-style: unset;

    }

    &.indent-1 {
        margin-left: 1rem;
    }
}

summary {
    cursor: pointer;
}

.avatar {
    position: relative;
    overflow: hidden;
    border: 1px solid $light-gray;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    margin: auto;

    &.small {
        width: 2rem;
        height: 2rem;
    }

    &.medium {
        width: 3rem;
        height: 3rem;
    }

    &.large {
        width: 5rem;
        height: 5rem;
    }

    &>img {
        width: 100%;
        height: auto;
    }
}

.hover-svg {
    cursor: pointer;

    &:hover {
        opacity: .5 !important;
    }
}

.hidden-input {
    position: absolute;
    opacity: 0;
}

.primary-color {
    color: $primary-color !important;
}

.warning-color {
    color: $warning-color !important
}

.danger-color {
    color: $danger-color;
}

.success-color {
    color: $light-green-color;
}

.black {
    color: $black;
}

.xs-font {
    font-size: $x-small-font-size;
}

.small-font {
    font-size: $small-font-size;
}

.base-font {
    font-size: $base-font-size;
}

.normal-font {
    font-size: $normal-font-size;
}

.abs-center {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
}

.h100 {
    height: 100%;
}

.attention {
    font-weight: 600;
    text-transform: capitalize;
}

.box-shadow-mini {
    box-shadow: $box-shadow-mini;
}

// remove the default eye icon for  ie and ms edge browsers
input[type="password"]::-ms-reveal {
    display: none;
}

.pointer {
    cursor: pointer;
}

.line {
    border: none;
    border-top: 2px solid $light-gray;
    width: 100%;

    &.broken {
        border-top-style: dashed;
    }
}

.vert-line {
    height: 90%;
    width: 1px;
    background: #dbdee5;
    margin: 0 .5rem;
}

.prevent-select {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

ul.styled-list {
    display: flex;
    flex-direction: column;
    gap: .2rem;
    overflow: hidden auto;
    max-height: 20rem;
    padding-right: .5rem;

    li {
        border-bottom: 1px solid $light-gray;
        padding: .4rem 0;
    }
}

.circle-tag {
    background: #297fff;
    color: white;
    width: 15px;
    border-radius: 50%;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    >span {
        display: flex;
        align-items: center;
        margin-right: 1px;
        margin-top: 1px;
        font-size: 10px;
    }
}

.link-hover {
    cursor: pointer;

    &:hover {
        color: $primary-color;
        text-decoration: underline;
    }
}