@use '../../variables' as *;

$table-border-color: #d1dae7c2;
$table-head-border-color: #b5d0f9;

.tk-table-container {
    position: relative;
    overflow: auto;
    white-space: nowrap;
    height: max-content;
    max-height: 100%;
    width: 100%;
    display: flex;
    min-height: 5rem;
}

.tk-table-caption {
    position: relative;
    padding: 1rem .5rem;
}


.tk-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    table-layout: fixed;
    vertical-align: top;

    &--empty {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1rem;

        .tk-empty>svg {
            margin: 1rem;
            width: 8rem;
        }
    }

    .item-wrapper {
        word-break: break-word;
    }

    thead {
        text-transform: capitalize;
        position: sticky;
        top: 0;
        z-index: 2;

        th {
            background-color: $background-color;

            .head-right-border {
                display: none;
            }

            &.fixed-col {

                .head-right-border {
                    border-right: 1px solid $table-border-color;
                    display: flex;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0
                }
            }

            .item-wrapper {
                text-wrap: nowrap;
                padding: 0 .5rem;
            }

            .head-item {
                position: relative;
                display: flex;
                align-items: center;
            }

            .head-border {
                position: absolute;
                border-bottom: 2px solid $table-head-border-color;
                left: 0;
                right: 0;
                bottom: 0;
            }

            .head-top-border {
                position: absolute;
                border-top: 2px solid $background-color;
                left: 0;
                right: 0;
                top: -1px;
            }
        }

        span {
            font-weight: 500;
        }

        th:not(.active-sort) {
            color: $fade-gray2;
        }

    }

    tbody {
        position: relative;
        background-color: $background-color;

        tr {
            &.disabled-row {
                opacity: .5;
                pointer-events: none;
            }

            td.fixed-col {
                .table-border {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 1px;
                    border-right: 1px solid $table-border-color;
                }
            }

            &:nth-child(odd) {
                background: $primary-lighter-alt-color;

                &:hover {
                    background: #c6d9ff;

                    td.fixed-col {
                        background: #c6d9ff;
                    }
                }

                td.fixed-col {
                    background: $primary-lighter-alt-color;
                }
            }

            &:nth-child(even) {
                background: #F6F9FF;

                &:hover {
                    background: #c6d9ff;

                    td.fixed-col {
                        background: #c6d9ff;
                    }
                }

                td.fixed-col {
                    background: #F6F9FF;
                }
            }
        }
    }


    thead th,
    tbody td {
        position: relative;
        width: 150px;
        border: 1px solid $table-border-color;
        border-top: none;
        border-left: none;
        border-bottom: none;

        &:last-child {
            border: none;
        }

        &>.item-wrapper {
            padding: 0.5em;
            text-align: left;
            font-weight: normal;
            margin: auto;
            overflow: hidden;
            text-wrap: balance;
        }

        &.fixed-col {
            position: sticky;
            left: 0;
            z-index: 1;
            border: none;
        }

    }

    tfoot tr {
        background: hsl(220, 50%, 80%);
    }

    thead,
    tfoot {
        -ms-overflow-style: none;

        &::-webkit-scrollbar-track {
            background: $white;
        }
    }

    thead::-webkit-scrollbar,
    tfoot::-webkit-scrollbar {
        visibility: hidden;
    }

    th>div {
        display: flex;
        gap: .5rem;
    }

    .table-checkbox {
        width: 2rem;
    }

    svg {
        opacity: .7;

        &:hover {
            opacity: 1;
        }

        &.tk-table__sort--active {
            opacity: 1;
        }
    }

    &__sort {
        display: flex;
        justify-content: center;
        cursor: pointer;
    }

    &__pagination {
        display: flex;
        gap: .5rem;
        position: relative;
        flex-wrap: wrap;
        margin: auto;
        margin-top: .8rem;

        &__rpp {
            display: flex;
            align-items: center;
            gap: 1rem;
        }

        &__info {
            display: flex;
            align-items: center;
            gap: .5rem;
            padding: 1px;
        }

        &__controls {
            display: flex;
            align-items: center;
            gap: .5rem;

            svg {
                opacity: .7;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}

@media (max-width: 67em) {

    // do more here...
    .tk-table {
        &__pagination {
            justify-content: flex-end !important;
        }
    }
}