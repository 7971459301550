// estimated mobile vars
$mobile-form-width: 27rem;

// start declaring all the reusable variables here...
$background-color: #F1F4FA;
$background-alt-color: #d2dbee;
$background-dark-color: #e4e8ef;

$secondary-color: #565656;
$secondary-bright-color: #666666;

$primary-color: #0052CC;
$primary-accent-color: #00388b;
$primary-bright-color: #0167ff;
$primary-fade-color: #0051ca52;
$primary-lighter-alt-color: #E1EDFF;

$danger-color: #E00606;
$danger-light-color: #FFD9DA;
$danger2-color: #F22828;
$danger2-light-color: #FBBABA;
$gold-color: #E7B400;
$light-blue-color: #64a2ff;
$light-green-color: #039D00;
$dark-green-color: #008000;
$accent-green-color: #b5ffb5;
$warning-color: #f19c00;
$dark-yellow-color: #B88F00;
$accent-yellow-color: #FFEBA4;
$yellow-color: #937409;
$brown-color: #8A5A00;
$accent-brown-color: #FFD5A4;
$blue-color: #134FA7;
$accent-blue-color: #bfd9ff;
$gray: #f0f0f0;
$white: #FFFFFF;
$black: #424242;
$light-gray: #E2E2E2;
$semi-light-gray: #727272;
$lighter-gray: #f1f1f1;
$lightest-gray: #EBEBEB;
$dark-gray: #EBEDEF;
$fade-gray2: #9B9B9B;
$fade-gray: #848484;
$fade-gray-light: #D1D1D1;
$scroll-body-color: #ECECEC;
$border-radius: 15px;
$transition-duration: .3s;

// shadow
$box-shadow: 0px 0px 3px #00000036;
$box-shadow-bottom: 0 2px 3px #c7c7c7;
$box-shadow-bottom-right: 2px 2px 4px #0000004f;
$box-shadow-mini: 0px 0px 2px 0px rgb(159 159 159 / 31%);
$box-shadow-minimal: 0px 1px 1px rgba(0, 0, 0, 0.2117647059);

// fonts
$default-font: "Twemoji Country Flags", Inter, Tahoma, Geneva, Verdana, aAial, sans-serif;
$arabic-font: "Noto Sans Arabic", sans-serif;

$x-small-font-size: 10px;
$small-font-size: 12px;
$base-font-size: 14px;
$normal-font-size: 16px;
$large-font-size: 24px;