.tk-transfer {
    position: relative;

    &__controls {
        margin: auto 1rem;

        button svg {
            width: 2rem;
        }
    }

    &__left {
        flex: 1;
    }

    &__right {
        flex: 1;
    }

    &__loading {
        position: absolute;
        bottom: -.8rem;
        transform: translateX(-50%);
        left: 50%;
    }
}