@use '../../variables' as *;

$layout-width: 98.7%;

.tk-auth-layout {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;

    &__header {
        padding: 1rem;
        min-height: 5.3rem;
        width: $layout-width;
        padding-left: 0;
        padding-right: 0;

        &.has-shadow {
            box-shadow: 0 0px 3px #c7c7c7;
        }
    }

    &__sidebar-toggle {
        position: absolute;
        left: -2rem;
        background: white;
        width: 2rem;
        padding: .5rem;
        border-radius: 40px;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__content {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    &__inner {
        position: relative;
        width: $layout-width;
        display: flex;
        background: $white;
        border-radius: 15px;
        box-shadow: 0px 3px 8px #dddddd;

        flex-grow: 1;
        height: 5px;
        margin-bottom: 1rem;

        &-wrapper {
            padding: 1rem;
            width: 100%;
        }
    }

    &-container {
        display: flex;
        width: 100%;
        height: 100%;
        gap: .5rem;
    }

    &__sidebar-item {
        display: flex;
        align-items: center;
        gap: 1rem;

        &>div {
            display: flex;
            align-items: center;
        }
    }

    &__sidebar-footer {
        cursor: pointer;
        display: flex;
        align-items: center;
        border-radius: 40px;
        color: $white;
        background-color: $secondary-color;

        &.danger {
            color: $white;
            background-color: $danger2-color;
            opacity: .7;

            &:hover {
                opacity: 1;
                background-color: $danger2-color;
            }
        }

        &:hover {
            background-color: $secondary-bright-color;
        }

        &__inner {
            display: flex;
            width: 100%;
            // padding: 1.2rem;
            // padding-left: 1.5rem;
            padding: 0.5rem 1rem;
            padding-left: 1.5rem;
            min-height: 2.5rem;
            max-height: 2.5rem;
        }

        &__item {
            display: flex;
            align-items: center;
            gap: 1rem;
        }
    }
}