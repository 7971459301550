@use '../variables' as *;

$mobileViewHeight: calc(100vh - 18rem);
$bg-color: #f9f9f9;

.tk-company-forms {
    position: relative;

    &-upsert {
        position: relative;

        strong {
            color: $black;
        }

        .form-field {
            position: relative;
            background-color: $gray;
            cursor: pointer;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 8rem;
            height: 6rem;
            border-radius: $border-radius;
            gap: .5rem;

            &:hover {
                background-color: $accent-blue-color;
                color: $blue-color;
            }

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .form-drag-icon,
            .form-normal-drag-icon {
                position: absolute;
                top: 7%;
                left: 5%;
            }

        }

        &__inner {
            display: flex;
            height: 100%;

            .form-side-panel {
                width: 40%;
                height: 100%;
                display: flex;
                flex-direction: column;
                border-right: 1px solid $light-gray;
                padding: .5rem 1rem;
                gap: 1rem;
                min-width: 16rem;

                &__title {
                    margin-top: 1rem;
                }
            }

            .form-control-fields {
                position: relative;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                overflow-y: auto;
                padding-bottom: 3rem;
                margin-bottom: 1rem;
                background: #f9f9f9;
                padding-left: 1rem;
                padding-top: 1rem;
                border-radius: 10px;

                &__group {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                }

                &__items {
                    display: flex;
                    flex-wrap: wrap;
                    gap: .5rem;
                }

                .drag-icon {
                    outline: none !important;
                    cursor: grab;
                }
            }

        }

        &__content {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        &__header {
            display: flex;
            width: 100%;
            min-height: 2rem;
            padding: 1rem;
            padding-bottom: 0;
            gap: .5rem;
            align-items: center;
            flex: 1 1;

            .group {
                min-width: 27rem;
                flex: 1;
                display: flex;
                justify-content: space-between;

                .left {
                    flex: 1
                }

                .right {
                    height: max-content;
                    margin-top: 6px;
                    margin-left: .5rem;
                }
            }

            .group-extra {
                width: 28rem;
            }
        }

        &__body {
            display: flex;
            padding: 1rem;
            flex-grow: 1;

            &__left {
                flex: 1;
                display: flex;
                justify-content: center;
                background: $bg-color;
                border-radius: 10px;
                padding: .5rem;
                height: $mobileViewHeight;
                overflow: auto;

                .form-mobile-view {
                    display: flex;

                    &__box {
                        position: relative;
                        border: 1px solid $light-gray;
                        border-radius: $border-radius;
                        padding: 1rem;
                        background: $bg-color;
                        height: max-content;
                        min-height: $mobileViewHeight;
                        width: $mobile-form-width;
                        min-width: $mobile-form-width;
                        max-width: 30rem;

                        &.drop-zone--is-dropping {
                            border: 1px dashed #b9b9b9;
                        }

                        &.drop-zone--is-disabled {
                            cursor: no-drop;
                            background: linear-gradient(#F9F9F9, #EDEDED);
                        }
                    }

                    &__sortable-box {
                        width: 100%;
                    }

                    .sort-item {
                        &.temp {
                            &:hover {
                                border: unset !important;
                            }
                        }

                        .left,
                        .right {
                            display: none;

                        }

                        .drag-icon {
                            outline: none !important;
                            cursor: grab;
                        }

                        .label-title {
                            display: none;
                        }

                        .remove-icon {
                            color: red;
                        }

                        &--simple {
                            position: relative;
                        }

                        &--is-active {
                            border: 2px dashed $blue-color !important;

                            .label-title {
                                display: inline-block !important;
                            }

                            .show-field-type {
                                display: inline-block;
                            }

                            .left,
                            .right {
                                display: flex;

                            }
                        }

                        &--has-error {
                            border: 2px dashed $danger-color !important;
                        }

                        &:not(&--is-dropping) {
                            &:hover {
                                border: 1px dashed #b9b9b9;

                                .show-field-type {
                                    display: inline-block;
                                }

                                .label-title {
                                    display: inline-block !important;
                                }

                                .left,
                                .right {
                                    display: flex;

                                }

                                .remove-icon {
                                    z-index: 300;
                                }
                            }
                        }

                        &--is-dropping-to-top {
                            border-top: 1px dashed #b9b9b9;
                        }

                        &--is-dropping-to-bottom {
                            border-bottom: 1px dashed #b9b9b9;
                        }
                    }

                    .dropped-item {
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        font-size: $small-font-size;
                        width: 100%;
                        word-wrap: break-word;
                        padding: 0 .5rem;
                        overflow: hidden;

                        &__header {
                            min-height: 2rem;
                            max-width: 14rem;
                            display: flex;
                            align-items: center;
                        }

                        &__label {
                            display: flex;
                            align-items: center;
                            font-weight: 500;
                            text-transform: capitalize;
                            gap: .5rem;
                        }

                        &__content {
                            flex: 1;
                            margin-bottom: .5rem;
                            width: 100%;
                        }

                        &--inline {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: .5rem;

                            .dropped-item__header {
                                order: 2;
                            }

                            .dropped-item__content {
                                width: min-content;
                                flex: unset;
                                margin: 0;
                            }
                        }

                        &--no-header {
                            .dropped-item {
                                &__content {
                                    margin-top: .5rem;

                                    p {
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }
                }

                .mobile-view-title {
                    position: absolute;
                    bottom: 0;
                }
            }

            &__right {
                height: $mobileViewHeight;
                overflow: auto;
                position: relative;
                padding: 0 1rem;
                width: 28rem;

                &.has-error {
                    .form-validations__box {
                        max-height: calc(100vh - 27.5rem);
                    }
                }
            }

            .main-controls {
                position: absolute;
                bottom: 0;
                right: 2rem;
            }
        }
    }
}

.form-validations {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    .tk-select__label {
        font-size: $small-font-size !important;
    }

    .tk-select-inner__input-container {
        max-width: 12rem;
        font-size: $small-font-size;
    }

    &__box {
        overflow: auto;
        max-height: 60vh;
        padding: 1rem;
        width: 100%;
        border-radius: $border-radius;
        background-color: $gray;
        min-height: 10rem;
        max-height: calc(100vh - 21.5rem);
    }

    &__content {
        margin-bottom: 4rem; // must be the same with the footer height
    }

    &__footer {
        position: absolute;
        bottom: 0;
        background: $gray;
        margin: auto;
        left: 0;
        right: 0;
        padding: 1rem;
        height: 4rem;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }
}

.mobile-input {
    position: relative;
    background: $bg-color;

    .input-box {
        min-height: inherit;
        border: 1px solid $primary-color;
        border-radius: 5px;
        background-color: $white;
        box-shadow: $box-shadow-bottom;
        width: 100%;
    }

    .field-info-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: .5rem;
    }

    p {
        margin-top: 0;
    }

    &.input-size {
        position: relative;
        min-height: 2rem;
    }

    &--toggle {
        display: flex;
        align-items: center;

        input {
            pointer-events: none;
        }
    }

    &--text-input {
        position: relative;
        min-height: 2rem;
    }

    &--text-area-input {
        min-height: 5rem;
    }

    &--checkbox {
        input[type="checkbox"] {
            width: 1.2rem;
            height: 1.2rem;
            display: flex;
            flex: unset;
            pointer-events: none;
        }

        .items {
            gap: .2rem;
        }
    }

    &--radio {
        input[type="radio"] {
            width: 1.3rem;
            height: 1.3rem;
            display: flex;
            flex: unset;
            pointer-events: none;
        }

        .items {
            gap: .2rem;
        }
    }

    &--dropdown {
        .dropdown-indicator {
            width: min-content;
            position: absolute;
            top: -8px;
            right: 0;
        }
    }

    &--file-upload {
        display: flex;
        gap: .5rem;

        .box-file,
        .box-add {
            border: 1px solid transparent;
            width: 3rem;
            height: 3rem;
            background-color: #cccccc;
            border-radius: 5px;
        }

        .box-file {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 2rem;
                height: auto;

                &.image {
                    padding-top: 5px;
                    position: relative;
                    padding-left: 3px;
                }
            }
        }

        .box-add {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                color: $primary-color;
            }
        }
    }

    &--questionnaire {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &__header-info {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding-right: 1rem;
        }

        &__group {
            position: relative;
            border: 1px solid #0000000f;
            background: #e7f1ff;
            margin: 0 0.5rem;
            padding: 1rem;
            border-radius: 7px;
            margin-top: .5rem;
            padding-right: 1rem;
        }

        .group-title {
            font-size: 1.5em;
            margin-bottom: .5rem;
        }

        &__item {
            display: flex;
            gap: 1rem;

            &__question {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;

                h3 {
                    margin-bottom: .5rem;
                }
            }


        }

        &__list {
            word-break: break-all;

            &.has-title {
                margin-left: 1.5rem;
                margin-top: 1rem;
            }
        }

        .star-rating {
            position: relative;
            display: flex;
            flex-direction: column;
            width: min-content;
            justify-content: center;
            align-items: center;

            &__icon {
                position: relative;
                width: min-content;

                svg {
                    width: 1.8em;
                    height: auto;
                    color: #ecc800;
                }

                .number-overlay {
                    position: absolute;
                    color: $white;
                }
            }

            &__value {
                position: relative;
                color: $primary-color;
                font-weight: bold;
                text-align: center;
                min-width: 5rem;
                display: inline-block;
            }
        }
    }

    &--field {
        .show-field-type {
            display: none;
            white-space: nowrap;
        }

        &.column-layout {
            .field-info-box {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                &span:first-child {
                    white-space: nowrap;
                    row-gap: 0;
                }
            }
        }
    }

    &--probationary-appraisal,
    &--yearly-appraisal {
        &__inner {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        &__employee-details {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: .5rem;
            padding: .5rem;
        }
    }
}

.form-mobile-view__field-preview {
    position: relative;
    border: 2px dashed $blue-color !important;
    height: max-content;
    min-width: 20rem;
    padding: .5rem;
    display: flex;
    justify-content: center;

    .form-mobile-inputs {
        width: 100%;
        max-height: 30rem;
        overflow: hidden;

        .mobile-input {
            margin: auto;
            background-color: $bg-color;
            padding: .5rem;
        }
    }

}

.file-upload-placeholder {
    position: relative;
    display: flex;
    justify-content: flex-end;
    gap: .2rem;

    .placeholder-box {
        position: relative;
        width: 2.5rem;
        height: 2.5rem;
        border: 1px solid #0000000f;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f0f0f0;
    }
}


.group-info-fields {
    position: relative;
    border: 1px solid #0000000f;
    padding: .5rem 0;
    border-radius: 7px;
    background-color: $bg-color;

    &--column {
        .group-info-fields__content {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        padding: .5rem;
        padding-right: 2rem;
    }

    &__title {
        color: $primary-color;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__content {
        position: relative;
        padding: 0 .5rem;

        .dropped-item {
            display: flex;
            flex-direction: column;
            position: relative;
            font-size: 12px;
            width: 100%;
            word-wrap: break-word;
            padding: 0 0.5rem;
            overflow: hidden;

            &__content {
                margin-top: 0.5rem;
                flex: 1 1;
                margin-bottom: 0.5rem;
                width: 100%;
            }
        }
    }
}

.final-score-rating {
    border: 1px solid #0000000f;
    width: 10rem;
    border-radius: 7px;
    margin-left: auto;

    &__inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__label {
        padding: .5rem 1.5rem;
        font-size: 1.5em;
        text-align: center;
    }

    &__value {
        background-color: #e7f1ff;
        color: $primary-color;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 5px;
    }
}