@use '../../variables' as *;

.capsule {
    position: relative;
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    color: $white;
    font-size: $x-small-font-size;
    width: max-content;
    height: 1.2rem;
    box-shadow: $box-shadow;

    &.no-shadow {
        box-shadow: unset
    }

    &.with-border {
        border: 1px solid #b5cdfd;
    }

    &__title,
    &__content {
        padding: 0.3rem .5rem;

        &.red {
            color: $white;
            background-color: $danger-color;
            border-color: $danger-color;
        }

        &.yellow {
            color: #535353;
            background-color: #ffe500;
            border-color: #ffe500;
        }

        &.dark-yellow {
            color: $white;
            background-color: #D2BE0D;
            border-color: #D2BE0D;
        }

        &.green {
            color: $white;
            background-color: $dark-green-color;
            border-color: $dark-green-color;
        }
    }

    &__title {
        font-weight: 600;
        display: flex;
        align-items: center;
        position: relative;
        height: 100%;
        color: #3056BE;
        background: #A3C8FF;
        min-width: 4rem;
    }

    &__content {
        display: flex;
        align-items: center;
        height: 100%;
        background: #D0E3FF;
        color: #3056BE;
    }

    svg.info-icon {
        width: .8rem;
        color: #3056BE;
    }
}