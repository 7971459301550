@use '../../variables' as *;

.tk-tabs {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &.tab-capsule {
        position: relative;

        .tk-tabs__header {
            gap: 0;
            background-color: $background-color;
            width: min-content;
            border-radius: 10rem;
            margin: 0 auto;

            .tk-tabs__item {
                border-radius: 10rem;
                background-color: $background-color;

                &.active {
                    background-color: $primary-color ;
                    color: $background-color;
                }
            }

        }
    }

    &__header {
        position: relative;
        display: flex;
        gap: .2rem;
        overflow-x: auto;
        width: 100%;
        flex-shrink: 0;
    }

    &__body {
        position: relative;
        height: 100%;
        padding: 1rem;
        overflow: hidden auto;

        &.has-bg {
            background-color: $background-color;
        }
    }

    &__item {
        position: relative;
        padding-top: 1rem;

        &.head {
            cursor: pointer;
            background-color: $light-gray;
            font-weight: 500;
            padding: .5rem 1rem;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            min-width: 7rem;
            text-align: center;
            transition: all 100ms linear;
            opacity: .8;
            display: flex;
            align-items: center;
            justify-content: center;

            &.active {
                background-color: $background-color;
                font-weight: bold;
                transition: all 100ms linear;
                color: $primary-color;
                opacity: 1;
            }
        }

        &.content {
            position: relative;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
        }
    }
}