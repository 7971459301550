@use '../../variables' as *;

.table-list {
    position: relative;
    width: 100%;
    overflow: hidden auto;

    .check-count {
        position: absolute;
        right: .5rem;
        min-width: 1.5rem;
        min-height: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.small {
        font-size: 12px;

        .table-list__item.row {
            padding: .5rem 1rem;
        }

        .table-list__header,
        .table-list__body {
            padding-right: .5rem;
        }
    }

    &__header {
        position: sticky;
        width: 100%;
        background: white;
        z-index: 1;
        top: 0;
        font-weight: 500;
        color: $fade-gray2;
    }

    &__body {
        position: relative;

        &__inner {
            position: relative;
        }
    }

    &__item {
        &.row {
            position: relative;
            display: grid;
            padding: 1rem;
            align-items: center;

            &:not(&.head) {
                border-bottom: 2px solid $light-gray;
            }

            &.head {
                padding: .5rem 1rem;
                background-color: $primary-lighter-alt-color;
                border-radius: 5px;
            }

        }

        &__col {
            position: relative;
            width: 100%;

            &.head {
                display: flex;
                align-items: center;
            }

            .col-value {
                &.has-checkbox {
                    display: flex;
                    gap: .5rem;
                    align-items: center;
                    justify-content: flex-end;
                    width: 100%;
                }

                &.has-sort {
                    display: flex;
                    align-items: center;

                    .sort {
                        display: flex;
                        align-items: center;
                        gap: .5rem;

                        .sort-group {
                            display: flex;
                        }
                    }

                    svg {
                        width: 1rem;
                        cursor: pointer;

                        &:hover,
                        &.active {
                            color: $primary-color;
                        }
                    }
                }
            }
        }
    }
}