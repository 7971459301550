@use '../../../variables' as *;

.shift-status-capsule {
    position: relative;
    display: flex;
    gap: .1rem;
    border-radius: 10px;
    overflow: hidden;
    color: $white;
    font-size: $x-small-font-size;

    &>div {
        position: relative;
        background-color: gray;
        padding: 0.3rem .5rem;

        &:not(.st-main) {
            background-color: #d7d7d7;
            pointer-events: none;
        }

        &.active {
            opacity: 1;
            background-color: #9C0000;
            pointer-events: all;
        }
    }

    .st-main {
        background-color: #246DFF;
        min-width: 4rem;

        &.late {
            background-color: #EC291D;
        }
    }

    .st-extra-content {
        min-width: 5rem;

        &.red {
            color: $white;
            background-color: $danger-color;
            border-color: $danger-color;
        }

        &.yellow {
            color: #535353;
            background-color: #ffe500;
            border-color: #ffe500;
        }

        &.dark-yellow {
            color: $white;
            background-color: #D2BE0D;
            border-color: #D2BE0D;
        }

        &.green {
            color: $white;
            background-color: $dark-green-color;
            border-color: $dark-green-color;
        }
    }
}