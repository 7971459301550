@use '../../variables' as *;

.tk-page-layout {
    position: relative;
    height: 100%;

    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__top {
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }

    &__inner {
        height: 100%;

    }

    &__control {
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;

        &.ctrl-compact {

            > :first-child {
                flex: 1;
            }

            .search {
                min-width: unset;
                width: 100%;
                flex-grow: 1;
            }
        }
    }

    &__left {
        display: flex;
        gap: .5rem;
        align-items: center;

        .tk-input.search {
            width: 35vw;
            padding: 1rem;
        }
    }

    &__right {
        display: flex;
        gap: .5rem;
        align-items: center;
    }

    &__body {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
    }

    &__item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: .5rem;
    }

    .tk-card-page {
        &__inner {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}

.tk-filter {
    position: relative;
    height: 100%;
    display: flex;
    gap: 1rem;
    justify-content: space-between;

    &>:first-child {
        align-items: center;
        max-height: 6rem;
        overflow: auto;
        padding: .3rem;
    }

    >* {
        height: inherit;
    }
}

.tk-table-filter {
    position: relative;
    border-top: 1px solid $gray;
    padding: 1rem 0;
    padding-bottom: 0;
}