@use '../../variables' as *;


$arrow-size: 8px;

.tk-popover {
    position: relative;


    &__content {
        position: absolute;
        z-index: 999;
        background-color: #fff;
        border: 1px solid $light-gray;
        padding: 10px;
        border-radius: 4px;
        box-shadow: 0 4px 11px hsla(0, 0%, 0%, 0.1);

        &::before {
            content: '';
            position: absolute;
            border-style: solid;
        }

        &.no-arrow {
            &::before {
                content: '';
                border: none !important;
            }
        }
    }

    &--top {
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);

        &::before {
            bottom: -$arrow-size;
            left: 45%; // 50% minus arrow size
            border-width: $arrow-size $arrow-size 0;
            border-color: $white transparent transparent transparent;
            transform: rotate(0);
        }
    }

    &--top-left {
        bottom: 100%;
        left: 0;

        &::before {
            bottom: -$arrow-size;
            left: $arrow-size;
            border-width: $arrow-size $arrow-size 0;
            border-color: $white transparent transparent transparent;
            transform: rotate(0);
        }
    }

    &--top-right {
        bottom: 100%;
        right: 0;

        &::before {
            bottom: -$arrow-size;
            right: $arrow-size;
            border-width: $arrow-size $arrow-size 0;
            border-color: $white transparent transparent transparent;
            transform: rotate(0);
        }
    }

    &--left {
        top: 50%;
        right: 100%;
        transform: translateY(-50%);
        margin-right: 5px;

        &::before {
            right: -13px;
            top: 45%; // 50% minus arrow size
            margin-left: -5px;
            border-width: 10px 10px 0;
            border-color: #fff transparent transparent transparent;
            transform: rotate(-90deg);
        }
    }

    &--right {
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        margin-left: 5px;

        &::before {
            left: -11px;
            top: 45%; // 50% minus arrow size
            border-width: $arrow-size $arrow-size 0;
            border-color: $white transparent transparent transparent;
            transform: rotate(90deg);
        }
    }

    &--bottom {
        top: 100%;
        left: 50%;
        transform: translateX(-50%);

        &::before {
            top: -$arrow-size;
            left: 45%; // 50% minus arrow size
            border-width: $arrow-size $arrow-size 0;
            border-color: $white transparent transparent transparent;
            transform: rotate(180deg);
        }
    }

    &--bottom-left {
        top: 100%;
        right: 0;

        &::before {
            top: -$arrow-size;
            right: $arrow-size;
            border-width: $arrow-size $arrow-size 0;
            border-color: $white transparent transparent transparent;
            transform: rotate(180deg);
        }
    }

    &--bottom-right {
        top: 100%;
        left: 0;

        &::before {
            top: -$arrow-size;
            left: $arrow-size;
            border-width: $arrow-size $arrow-size 0;
            border-color: $white transparent transparent transparent;
            transform: rotate(180deg);
        }
    }
}