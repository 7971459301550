@use '../../variables' as *;

.tk-loader {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &__inner {
        display: flex;
        width: 100%;
        height: 100%;
    }

    &__overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        background: #0000003d;
        z-index: -1;
    }

    &.centered-fixed {
        position: fixed;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
    }

    &.relative {
        position: relative;

        &.white {
            svg {
                transform: scale(1.8);
                color: white;
            }
        }
    }

    svg {
        color: #0052cc;
    }
}

.tk-mini-loader {
    position: relative;

    svg {
        width: 100%;
        height: auto;
    }
}