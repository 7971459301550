@use '../variables' as *;

.tk-employees-work-history {
    position: relative;

    &__modal-content-view {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        flex: 1;

        .time-in {
            margin: auto;
        }

        &__top-extra {
            position: relative;
            display: flex;
            flex-direction: column;
            background: $background-color;
            border: 1px solid #c2d9ff;
            border-radius: 14px;
            margin-top: 3rem;
            flex: 1;
            padding: 0 .5rem;
            padding-bottom: 1rem;

            &__content {
                width: 90%;
                margin: auto;
                min-width: 15rem;
                gap: 1.5rem;
            }

            &__footer {
                width: 90%;
                margin: auto;
            }

            &>div {
                justify-content: space-between;
                height: 100%;
            }

            .note {
                p {
                    margin: 0;
                    margin-top: .3rem;
                }
            }

            .box {
                position: relative;
                background: #E4EDFF;
                border-radius: 10px;
                padding: 1rem;
                padding-top: 1.5rem;
                width: 9rem;
                border: 1px solid #dfe9fd;

                &__value {
                    font-weight: bold;
                }

                &__tag {
                    font-size: $x-small-font-size;
                    position: absolute;
                    top: -15%;
                    right: 10%;
                }
            }

            .top,
            .bottom {
                justify-content: space-around;
            }
        }
    }
}