@use '../../variables' as *;

.tk-lettered-avatar {
    position: relative;
    text-align: center;
    min-width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary-color;
    overflow: hidden;
    cursor: pointer;

    .tk-loader {
        width: 60%;
    }

    &__content {
        width: inherit;
        height: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.has-border {
        border: 2px solid $white;
        box-shadow: $box-shadow;
    }

    &__content {
        white-space: nowrap;
        overflow: hidden;
        font-size: $large-font-size;

        &.small {
            font-size: $base-font-size;
        }
    }

    &.light {
        color: #000;
    }

    &.dark {
        color: #fff;
    }

    &.medium {
        min-width: 48px;
        width: 48px;
        height: 48px;
    }

    &.large {
        min-width: 60px;
        width: 60px;
        height: 60px;
    }

    &__container {
        position: relative;

        & .remove {
            position: absolute;
            left: 100%;
        }

        & .edit {
            position: absolute;
            opacity: 0;
            transition: opacity $transition-duration ease;

            &:hover {
                opacity: 1;
                transition: opacity $transition-duration ease;
            }
        }
    }
}