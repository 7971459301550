@use '../../variables' as *;

$custom-select-bg-color: #efefef;
$custom-select-border-color: #E2E2E2;
$custom-select-hover-color: #DEEBFF;

.custom-select-wrapper {
    position: relative;
    display: flex;

    >.label {
        color: $fade-gray;
        display: flex;
        align-items: center;
        padding-left: 8px;
        padding-right: 1rem;
        font-size: 14px;
        width: auto !important;
        flex: unset !important;
    }

    &.outlined {
        border-bottom: 1px solid $light-gray;

        .custom-select-control {
            background-color: transparent !important;
            border: none !important;
            border-radius: unset !important;
            padding: 4px 0 !important;
            border: unset
        }
    }

    &.right {
        .custom-select__value {
            justify-content: flex-end;
        }

        .custom-select__multi-value {
            flex: 1;
            justify-content: flex-end;
            margin-right: .5rem;
        }
    }

    &.disabled {
        opacity: 1 !important;

        .label {
            opacity: 1;
        }

        .custom-select {
            opacity: .5;
            pointer-events: none;
        }
    }

    &.readonly {
        opacity: 1;

        .after-extra {
            display: none;
        }
    }

    &.noborder {
        border: none !important;
    }

    &.filter {
        .indicator-clear {
            width: 1.5rem;
        }
    }

    .hidden-input {
        top: 0;
        left: 0;
        z-index: -1;
        bottom: 0;
    }
}

.custom-select {
    position: relative;
    width: 100%;
    height: max-content !important;

    &__multi-value {
        max-height: 15rem;
        overflow: auto;
    }

    &__value {
        width: 100%;
        margin-right: .5rem;
    }

    &-control {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 10px;
        border: 1px solid $custom-select-border-color;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        background: $custom-select-bg-color;
        min-height: 1.7rem;
        font-size: 14px;
        align-items: center;
        border-radius: 25rem;
        height: 100%;

        &:focus {
            outline: none;
        }

        .after-extra {
            display: flex;
            align-items: center;
            height: 1.2rem;

            .indicator-separator {
                align-self: stretch;
                width: 1px;
                background-color: hsl(0, 0%, 80%);
                margin: auto;
                margin-left: 5px;
                height: 100%;
            }

            .dropdown-arrow {
                display: flex;
                align-items: center;
                height: 1rem;
            }

            svg {
                color: hsl(0, 0%, 80%);

                &:hover {
                    color: hsl(0, 0%, 60%);
                }
            }
        }

        .icon-box {
            background: #ff6262;
            margin-right: -7px;
            margin-left: 5px;
            padding: 0 2px;
            padding-right: 5px;
            color: white;
            border: 1px solid #ff6262;

            svg {
                margin-top: 2px;
            }
        }

        .indicator-loading,
        .indicator-clear {
            margin-left: auto;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .indicator-clear {
            height: max-content;
            width: 1.2rem;

            svg {
                width: 100%;
                height: auto;
            }
        }

        // target all clear with svg children
        .clear {
            path {
                pointer-events: none;
            }
        }
    }

    &--focused {
        .custom-select-control {
            background: $white;
        }
    }

    &-dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background: $white;
        border: 1px solid $custom-select-border-color;
        border-radius: 4px;
        z-index: 5000;
        margin-top: .5rem;
        margin-bottom: .5rem;
        overflow: hidden;
        min-height: 15rem;

        &--top {
            bottom: 100% !important;
            left: 50% !important;
            transform: translateX(-50%) !important;
            top: unset !important;
        }

        &--bottom {
            top: 100% !important;
            left: 50% !important;
            transform: translateX(-50%) !important;
            bottom: unset !important;
        }
    }

    &-search {
        width: 100%;
        padding: 8px;
        border: none;
        border-bottom: 1px solid $custom-select-border-color;
        box-sizing: border-box;
    }

    &-options {
        list-style: none;
        padding: 0;
        margin: 0;
        overflow-y: auto;
        max-height: 200px;
    }

    &-option {
        padding: 0 10px;
        min-height: 2.5rem;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover,
        &--focused {
            background: $custom-select-bg-color;
        }

        &--selected {
            background: $custom-select-hover-color;
        }

        &--disabled,
        &--fixed {
            opacity: .5;
        }
    }

    &-search {
        background: $custom-select-bg-color;
        outline: none;

        &:focus {
            outline: none;
            background: $white
        }
    }
}