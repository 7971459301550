@use '../variables' as *;

.tk-dashboard {
    position: relative;
    flex: 1;
    padding: 1rem;
    padding-left: 0;
    padding-top: 0;

    &-company {
        height: 100%;
        gap: 1rem;

        &__content {
            height: 100%;
            width: 100%;

            &__inner {
                height: 100%;
                width: 100%;
                padding: 1.25rem;
                display: flex;
                flex-direction: column;
                gap: 1rem;
            }
        }

        &__inner {
            flex: 1 1;
            position: relative;
            width: 100%;
            background: $white;
            border-radius: 15px;
            height: 100%;
            box-shadow: 0px 3px 8px #dddddd;
            width: calc(100vw - 48rem);

            .tk-login-activities {

                .tk-card-page__content {
                    padding: 0;
                    height: calc(100vh - 16rem);
                }
            }
        }

        &__right {
            width: 100%;
            min-width: 20rem; // Minimum width
            max-width: 27vw; // Maximum width relative to viewport width

            .notifications,
            .history {
                flex: 1;
                overflow-y: auto;

                ul {
                    width: 100%;
                    list-style-type: none;
                    padding: 0 10px 0 0;
                    margin: 0;
                    overflow-y: auto;
                    height: calc(36vh - 5rem);

                    li {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        align-items: flex-start;

                        margin-bottom: 0.5rem;
                        padding: .8rem;
                        background-color: $white;
                        border-radius: 5px;
                        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
                        transition: background-color $transition-duration ease;
                        animation: fadeIn 0.5s forwards;

                        &:hover {
                            // background-color: #e0e0e0 !important; /* Apply for alternating background colors */
                            background-color: #e1edff;
                        }

                        .message {
                            width: 75%;
                        }

                        .createdAt {
                            opacity: 0.7;
                            padding-top: 5px;
                            font-size: x-small;
                            float: right;
                        }

                        @keyframes fadeIn {
                            from {
                                opacity: 0;
                                transform: translateY(-20px);
                            }

                            to {
                                opacity: 1;
                                transform: translateY(0);
                            }
                        }
                    }
                }

                .fade {
                    opacity: 0.5;
                }
            }
        }
    }

    &-super {
        padding: 1.25rem;
        background: $white;
        box-shadow: 0px 3px 8px #dddddd;
        border-radius: 15px;
        height: 100%;
        gap: 1rem;

        &__content {
            height: 100%;

            &__inner {
                height: 100%;
                padding: 1rem;
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
                gap: 1.5rem;

                .tk-dashboard-super__title {
                    grid-column: span 1;
                }

                >*:not(.tk-dashboard-super__title) {
                    grid-column: span 1;
                }

                .mini-card {
                    background-color: #f3f2f2;
                    padding: 20px;
                    border-radius: 7px;
                    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
                    transition: transform 0.3s ease;
                    margin: 10px;
                    max-width: 400px;
                    max-height: 200px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: left;
                }

                .mini-card:hover {
                    transform: translateY(-5px);
                }

                .value {
                    font-size: 2.5rem;
                    color: #0052CC;
                    margin-top: 10px;
                    font-weight: 400;
                }

                .title {
                    font-size: 1rem;
                    color: #333;
                    font-weight: 400;
                    margin-top: 10px;
                }


            }
        }

        &__inner {
            flex: 1 1;
            position: relative;
            width: 100%;
            background: $white;
            border-radius: 15px;
            height: 100%;
            // box-shadow: 0px 3px 8px #dddddd;
        }
    }
}