@use '../../variables' as *;

.tk-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    &__upgrade {
        display: flex;
        align-items: center;
        gap: .5rem;
        font-size: $base-font-size;
        margin-left: auto;

        &__title {
            text-transform: uppercase;
            font-weight: 500;
            font-size: $base-font-size;
            color: gray;
        }

        button {
            font-weight: bold;
            border-radius: 15px;
            width: max-content !important;
            font-size: $small-font-size;
            max-width: 8rem;
            max-height: 2rem;

            svg {
                color: yellow;
            }
        }
    }

    &__right {
        position: relative;
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-left: auto;
    }

    .tk-popover {
        &.active {
            .more-icon {
                transform: rotate(180deg);
                transition: $transition-duration transform ease-in-out;
            }
        }

        .tk-header__logo {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .tk-header__logo__content {
            .item {
                cursor: pointer;
                min-width: 6rem;
                display: flex;
                gap: 1rem;
                align-items: center;

                &.logout {
                    padding-right: 2px;
                }
            }

            .logout {
                svg {
                    opacity: 1;
                }
            }

            button {
                height: auto;
                padding: 0;
                display: flex;
                justify-content: space-between;
            }

            .upgrade {
                font-weight: bold;
                border-radius: 15px;
                width: max-content !important;
                max-width: 8rem;
                max-height: 2rem;
            }
        }

    }

    &__logo {
        position: relative;
        border-radius: 50%;
        transition: $transition-duration opacity ease-in-out;
        border: 2px solid $light-gray;

        &.active,
        &:hover {
            border: 2px solid $primary-color;

            .tk-lettered-avatar {
                opacity: .5;
                transition: $transition-duration opacity ease-in-out;
            }

            .overlay.show {
                opacity: 1;
            }
        }


        .overlay {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: $transition-duration opacity ease-in-out;
            opacity: 0;
            cursor: pointer;

            svg {
                width: 2rem;
                height: auto;
                transition: $transition-duration transform ease-in-out;
            }
        }
    }
}